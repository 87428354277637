<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="650">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create Message Template
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.TemplateName.item"
                        :rules="form.TemplateName.rule"
                        label="Template Name *"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" style="z-index: 1">
                    <span class="text-body-2">Message Body *</span>
                    <text-editor v-if="!modal_loader" v-model="form.TemplateText.item" :value="form.TemplateText.item" :color="'white'" :isEditing="true"></text-editor>
                    <v-input
                        error
                        v-if="!modal_loader"
                        readonly
                        v-model="form.TemplateText.item"
                        :rules="form.TemplateText.rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                        :hint="bodyhint"
                        persistent-hint
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.PlaceholderCount.item"
                        :rules="form.PlaceholderCount.rule"
                        label="Placeholder Count *"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';
import TextEditor from '../TextEditor';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                TemplateName: {item: '', rule: []},
                TemplateText: {item: '', rule: []},
                PlaceholderCount: {item: '', rule: []},
            },

            btn_loading: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false,
            notificationmessage: '',
            bodyhint: 'Template content can not be changed after approved except placeholder values inside double curly braces ({{...}}) when the message is sent.'
        }
    },

    components: {alertbox, 'text-editor': TextEditor},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.TemplateName.rule = [];
            this.form.TemplateText.rule = [];
            this.form.PlaceholderCount.rule = [];
        },

        async beforeCreateForm(flag){
            this.form.TemplateName.rule = [
                v => !!v || 'This is required',
            ];

            let body2 = /^.*>[^<].*$/.test(this.form.TemplateText.item);
            this.form.TemplateText.rule = [
                v => !!v || 'This is required',
                v => !v || body2 || 'This is required',
            ];

            this.form.PlaceholderCount.rule = [
                v => !!v || 'This is required',
                v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.onRuleChange();
            this.form.TemplateName.item = '';
            this.form.PlaceholderCount.item = '';
            this.form.TemplateText.item = '';
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>