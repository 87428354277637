<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="800">
    <v-form ref="ct" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            WhatsApp Chat {{` ${modalInfo.phoneNumber}`}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container id="chatrow" class="fill-height my-4"
        fluid style="overflow-y: scroll; height: 500px; align-items: flex-end">
        <v-overlay
                    :absolute="true"
                    :value="overlay"
                    >
                        <v-progress-circular
                    indeterminate
                    color="primary"
                    ></v-progress-circular>
                    </v-overlay>
            <v-row>
                <v-col cols="12" v-for="(item, index) in chat" :key="index" 
                        :class="['d-flex flex-row align-center my-2', item.replyBy == 'Admin' ? 'justify-end': null]">
                        <v-card color="#D4FAC1" style="width: 350px" v-if="item.replyBy == 'Admin'" class="mr-3"><v-card-text style="white-space: pre-line" v-html="format_text(item.message)"></v-card-text>
                        <v-card-text style="white-space: pre-line"><v-img :src="item.imageURL" /></v-card-text>
                        <v-card-subtitle class="text-sm-caption">{{item.replyDate}}</v-card-subtitle>
                        </v-card>
                        <v-avatar :color="item.replyBy == 'Admin' ? 'indigo': 'red'" size="36">
                        <span class="white--text">{{ item.replyBy[0] }}</span>
                        </v-avatar>
                        <v-card style="width: 350px" v-if="item.replyBy != 'Admin'" class="ml-3"><v-card-text style="white-space: pre-line" v-html="format_text(item.message)"></v-card-text>
                        <v-card-text style="white-space: pre-line"><v-img :src="item.imageURL" /></v-card-text>
                        <v-card-subtitle class="text-sm-caption">{{item.replyDate}}</v-card-subtitle>
                        </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="fill-height"
        fluid v-if="!readonly">
            <v-row no-gutters>
                <v-col>
                    <div class="d-flex flex-row align-center">
                    <v-textarea v-model="msg" auto-grow rows="1" placeholder="Type Something" :disabled="disable_chat" />
                    <v-btn icon class="ml-4" :disabled="disable_chat" @click="send"><v-icon>mdi-send</v-icon></v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="12" v-if="!disable_chat">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input prepend-icon="" persistent-hint :hint="imghint" background-color="white" label="Image" v-model="form.image.file" :rules="form.image.imgrule" type="file" accept="image/*"></v-file-input>
                                <v-card class="mb-2" style="height: 300px; z-index: 1" outlined @drop.prevent="addDropFile" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form.image.image == '' || form.image.image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col style="text-align: center">
                                            <p>Drag & Drop or Select Image from Folder</p>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form.image.image" :style="{'max-width': '100%', 'max-height': '100%'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form.image.btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="false" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
    </v-snackbar>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                TemplateText: {item: '', rule: []},
                PlaceholderCount: {item: '', rule: []},
                image: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                ApprovedByTwilio: {item: false}
            },

            btn_loading: false,
            overlay: false,

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            modal_loader: false,
            notificationmessage: '',
            bodyhint: 'Template content can not be changed after approved except placeholder values inside double curly braces ({{...}}) when the message is sent.',
            chat: [
            ],
            msg: null,
            disable_chat: false
        }
    },

    components: {alertbox, 'text-editor': TextEditor},

    watch: {
        dialog: {
        handler: async function(value) {
            await this.modalHandler();
            if(value == true){
                this.modal_loader = true;
                await this.handleValue();
                this.modal_loader = false;
                this.btn_loading = false;
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        this.$watch(() => this.form.image.file, this.handleImageMain, {deep: true});
    },

    methods: {
        async onRuleChange(after, before) {
            this.form.TemplateText.rule = [];
            this.form.image.imgrule = [];
            this.form.PlaceholderCount.rule = [];
        },

        async getChat(){
            this.overlay = true;
            let response = await this.$store.dispatch("getWhatsAppChatByID", {ID: this.modalInfo.ID});
            this.overlay = false;
            let dt = response.data.record;
            dt.forEach((element, index) => {
                if (index === dt.length - 1){
                    let days = moment().diff(moment(dt[index].replyDate), 'days');
                    this.disable_chat = days > 1;
                }

                dt[index].replyDate = dt[index].replyDate == null ? "N/A" : moment(dt[index].replyDate).format('DD-MM-YYYY hh:mm:ss A');
            });
            this.chat = dt;
            var element = document.getElementById("chatrow");
            setTimeout(function (){ 
            element.scrollTop = 999999999
            }, 10)
        },

        async beforeCreateForm(flag){
            this.form.TemplateText.rule = [
                v => !!v || 'This is required',
            ];

            this.form.PlaceholderCount.rule = [
                v => !!v || 'This is required',
                v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
                //self.$refs.image.resetValidation();
                //self.$refs.image.reset();

             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.onRuleChange();
            this.msg = "";
            this.form.image.image = "";
            this.form.image.file = "";
            this.disable_chat = false;
            await this.getChat();
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        //set the action to be sent to watcher to execute the create/edit function
        async onConfirm(value){
                if (this.ma.modal_action_type == 'edit'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        async send(){
            this.overlay = true;
            let response = await this.$store.dispatch("whatsAppChatReply", {ID: this.modalInfo.ID, message: this.msg, image: this.form.image.image});
            if (response.data.code === "AP000"){
                this.chat.push({
                    replyBy: response.data.record.replyBy,
                    replyID: response.data.record.replyID,
                    statusID: response.data.record.statusID,
                    statusName: response.data.record.statusName,
                    message: response.data.record.message,
                    imageURL: response.data.record.imageURL,
                    replyDate: moment(response.data.record.replyDate).format('DD-MM-YYYY hh:mm:ss A'),
                    deleteDate: response.data.record.deleteDate
                });
            }
            this.overlay = false;
            this.msg = "";
            this.form.image.image = "";
            this.form.image.file = "";

            var element = document.getElementById("chatrow");
            setTimeout(function (){ 
            element.scrollTop = 999999999
            }, 10)
        },

        support() {
            if (!window.DOMParser) return false;
            var parser = new DOMParser();
            try {
                parser.parseFromString('x', 'text/html');
            } catch(err) {
                return false;
            }
            return true;
        },

        format_text(text){
            if (text == "" || text == null) return "";

            else
            return text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
            .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
            .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
            .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
            .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');

            // extra:
            // --For underlined text--
            // ```Monospace font```
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);
            //let size = v.size / 1024 / 1024 < 5;

            if (v){
            this.form.image.btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form.image.image = base64img;
            this.form.image.btn_loading = false;
            this.imgValidate();
            }

            else{
                await this.$nextTick(async () => {
                this.form.image.image = '';
                this.form.image.btn_loading = false;
                });
            }
        },

        async imgValidate(){
                this.imghint = "Image size < 5mb";
                this.bodyhint = "Please only edit values in the {{}} area. Remain as it is if no changes.";
                if (this.form.image.image == '') return;
                
                let size = this.form.image.file.size / 1024 / 1024 < 5;
                if (!size){
                    this.form.image.image = '';
                    this.form.image.file = '';
                    this.form.image.imgrule = [
                        v => false || 'Please ensure image size is below 5mb',
                    ];
                }

                else{
                    this.form.image.imgrule = [];
                }
        },

        getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        addDropFile(e) { this.form.image.file = e.dataTransfer.files[0]; },
    }
}
</script>